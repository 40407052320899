<template>
  <div class="vg_wrapper">
    <div class="vg_button">
      <div style="margin: 10px; font-size: 17px">U8部门编码</div>
      <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
      <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
    </div>
    <el-table ref="bank" :data="cpttForm.cptt_dept_list" border class="vg_mt_16" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span>部门名称</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'cptt_dept_list.' + scope.$index + '.cptt_dept_name'">
            <el-select filterable v-model="cpttForm.cptt_dept_list[scope.$index].cptt_dept_code_id" placeholder="请选择部门" size="small" clearable>
              <el-option v-for="item in deptList" :key="item.dept_id" :label="item.dept_name" :value="item.dept_id"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span>部门编码</span>
        </template>
        <template slot-scope="scope">
          <el-form-item label-width="0" :prop="'cptt_dept_list.' + scope.$index + '.cptt_dept_code'">
            <el-input
              v-model="cpttForm.cptt_dept_list[scope.$index].cptt_dept_code"
              @input="cpttForm.cptt_dept_list[scope.$index].cptt_dept_code = helper.pureNumber(cpttForm.cptt_dept_list[scope.$index].cptt_dept_code)"
              maxlength="2"
              show-word-limit
              placeholder="请填写部门编码"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { keepEngAndSpace } from '@assets/js/regExUtil';
import { get } from '@api/request';
import { deptAPI } from '@api/modules/department';
import helper from '@assets/js/helper';

export default {
  name: 'CpttDeptCode',
  computed: {
    helper() {
      return helper;
    }
  },
  components: {},
  props: {
    cpttForm: {
      type: Object
    }
  },
  data() {
    return {
      selectionsList: [],
      deptList: [],
      key: 0
    };
  },
  created() {},
  mounted() {
    this.getDept();
  },
  methods: {
    keepEngAndSpace,
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //添加
    addRow() {
      let item = {
        cptt_dept_code_id: '',
        cptt_dept_code: ''
      };
      this.cpttForm.cptt_dept_list.push(item);
      this.cpttForm.cptt_dept_list.forEach((item, index) => {
        item.key = index;
      });
      this.selectionsList = [];
    },
    //多选
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    //多选删除
    delBank() {
      for (let i = 0; i < this.selectionsList.length; i++) {
        this.cpttForm.cptt_dept_list.forEach(item => {
          if (item.key === this.selectionsList[i].key) {
            item.delFlag = 1;
            let currentIndex = this.cpttForm.cptt_dept_list.indexOf(item);
            this.cpttForm.cptt_dept_list.splice(currentIndex, 1);
          }
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vg_wrapper ::v-deep.el-form-item {
  margin: 0;
  padding: 0;
}

.vg_wrapper ::v-deep .el-table td,
::v-deep.el-table th {
  padding: 0;
}

.vg_wrapper ::v-deep .el-table .cell {
  padding: 0;
}
</style>
