<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('cpttForm')" @cancel="cancel"></add-header>
    <el-form ref="cpttForm" :model="cpttForm" :rules="rules" label-width="140px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="公司编号" prop="cptt_no">
            <el-input
              v-model="cpttForm.cptt_no"
              maxlength="3"
              @input="cpttForm.cptt_no = helper.keepEngNum(cpttForm.cptt_no)"
              show-word-limit
              placeholder="请填写公司代码（仅支持数字和英文）"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司电话" prop="cptt_phone">
            <!-- <el-input v-model="cpttForm.cptt_phone" maxlength="30" show-word-limit placeholder="请填写公司电话"></el-input> -->
            <el-input @input="verifyCptt_phone" :value="cpttForm.cptt_phone" maxlength="30" show-word-limit placeholder="请填写公司电话"></el-input>
          </el-form-item>
          <el-form-item label="公司传真" prop="cptt_fax">
            <el-input v-model="cpttForm.cptt_fax" maxlength="30" show-word-limit placeholder="请填写公司传真"></el-input>
          </el-form-item>
          <el-form-item label="公司税号" prop="cptt_tax_no">
            <el-input v-model="cpttForm.cptt_tax_no" maxlength="30" show-word-limit placeholder="请填写公司税号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" :offset="4">
          <el-form-item label="公司名称（中）" prop="cptt_name">
            <el-input v-model="cpttForm.cptt_name" maxlength="100" show-word-limit placeholder="请填写公司名称（中文）"></el-input>
          </el-form-item>
          <el-form-item label="公司名称（英）" prop="cptt_name_en">
            <!-- <el-input v-model="cpttForm.cptt_name_en" maxlength="100" show-word-limit placeholder="请填写公司名称（英文）"></el-input> -->
            <el-input
              @input="val => (cpttForm.cptt_name_en = keepEngAndSpace(val))"
              v-model="cpttForm.cptt_name_en"
              maxlength="100"
              show-word-limit
              placeholder="请填写公司名称（英文）"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司地址（中）" prop="cptt_address">
            <el-input v-model="cpttForm.cptt_address" placeholder="请填写公司地址（中文）"></el-input>
          </el-form-item>
          <el-form-item label="公司地址（英）" prop="cptt_address_en">
            <el-input
              @input="val => (cpttForm.cptt_address_en = keepEngAndSpace(val))"
              v-model="cpttForm.cptt_address_en"
              placeholder="请填写公司地址（英文）"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
      </div>
      <el-table ref="bank" :data="cpttForm.cptt_bank_list" border class="vg_mt_16" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="48" align="center"></el-table-column>
        <el-table-column label="序号" width="48" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">银行代码</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'cptt_bank_list.' + scope.$index + '.cptt_bank_code'" :rules="[{ required: true }]">
              <el-input
                v-model="cpttForm.cptt_bank_list[scope.$index].cptt_bank_code"
                maxlength="50"
                show-word-limit
                placeholder="请填写银行代码（必填）"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">银行名称</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'cptt_bank_list.' + scope.$index + '.cptt_bank_name'" :rules="[{ required: true }]">
              <el-input
                v-model="cpttForm.cptt_bank_list[scope.$index].cptt_bank_name"
                maxlength="100"
                show-word-limit
                placeholder="请填写银行名称（必填）"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="银行信息">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'cptt_bank_list.' + scope.$index + '.cptt_bank_account'">
              <el-input type="textarea" :rows="1" v-model="cpttForm.cptt_bank_list[scope.$index].cptt_bank_account" placeholder="请填写银行信息"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column ble-column align="center">
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'defaultRadio'">
              <el-radio v-model="cpttForm.defaultRadio" :label="cpttForm.cptt_bank_list[scope.$index].k">默认</el-radio>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="vg_mt_16">
        <el-col>
          <CpttDeptCode ref="CpttDeptCode" :cpttForm="cpttForm"></CpttDeptCode>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { post } from '@api/request';
import { cpttAPI } from '@api/modules/comptitle';
import inputUser from '@/views/component/inputUser';
import addHeader from '@/views/component/addHeader';
import { keepEngAndSpace } from '@assets/js/regExUtil';
import CpttDeptCode from '@/views/SystemManagement/CpttManage/TabChild/CpttDeptCode.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'CpttAddMain',
  components: {
    CpttDeptCode,
    inputUser,
    addHeader
  },
  data() {
    return {
      cpttForm: {
        cptt_no: '',
        cptt_name: '',
        cptt_name_en: '',
        cptt_address: '',
        cptt_address_en: '',
        cptt_phone: '',
        cptt_fax: '',
        cptt_tax_no: '',
        key: 0,
        status: 0,
        cptt_bank_list: [],
        cptt_dept_list: [],
        defaultRadio: 0
      },
      newFlag: false,
      selectionsList: [],
      rules: {
        cptt_no: [{ required: true, trigger: 'blur' }],
        cptt_name: [{ required: true, trigger: 'blur' }],
        cptt_tax_no: [{ required: true, trigger: 'blur' }],
        cptt_name_en: [{ required: true, trigger: 'blur' }]
      }
    };
  },
  watch: {
    'cpttForm.defaultRadio': {
      immediate: true,
      handler: function (newVal) {
        this.cpttForm.cptt_bank_list.forEach(item => {
          if (item.k === newVal) {
            item.cptt_bank_default_flag = 1;
          } else {
            item.cptt_bank_default_flag = 0;
          }
        });
      }
    }
  },
  created() {},
  mounted() {
    let { syncCpttData } = this.$route.params;
    if (syncCpttData) this.cpttForm = syncCpttData;
  },
  methods: {
    keepEngAndSpace,
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              // console.log('klkk')
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {
          this.$message.info('已取消保存');
        });
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      this.cpttForm = Object.assign(staffForm, this.cpttForm);
      post(cpttAPI.addCptt, this.cpttForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump('/cptt_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });
            // this.jump(`/cptt_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
            this.resetForm('cpttForm');
            return;
          } else if (res.data.code === 999) {
            this.$message.warning('公司编号已存在');
            return;
          }
          this.$message.warning(res.data.msg);
        })
        .catch(res => {
          this.$message.warning(res.data.msg);
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('cpttForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.cpttForm.cptt_bank_list = [];
    },
    //添加
    addRow() {
      let default_flag = 0;
      if (this.cpttForm.key === 0) {
        default_flag = 1;
      }
      let item = {
        k: this.cpttForm.key,
        cptt_bank_code: '',
        cptt_bank_name: '',
        cptt_bank_account: '',
        cptt_bank_default_flag: default_flag
      };
      this.cpttForm.key = this.cpttForm.key + 1;
      this.cpttForm.cptt_bank_list.push(item);
      this.selectionsList = [];
    },
    //
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    //多选删除
    delBank() {
      let defaultDeleted = false;
      for (let i = 0; i < this.selectionsList.length; i++) {
        this.cpttForm.cptt_bank_list.forEach(item => {
          if (item.k === this.selectionsList[i].k) {
            if (this.defaultRadio === item.k) {
              defaultDeleted = true;
            }
            let currentIndex = this.cpttForm.cptt_bank_list.indexOf(item);
            this.cpttForm.cptt_bank_list.splice(currentIndex, 1);
          }
        });
      }
      if (defaultDeleted) {
        if (this.cpttForm.cptt_bank_list.length > 0) {
          this.defaultRadio = this.cpttForm.cptt_bank_list[0].k;
        } else {
          this.defaultRadio = this.cpttForm.key;
        }
      }
    },
    // 公司只能输入中文
    verifyCptt_name(event) {
      this.cpttForm.cptt_name = this.helper.keepChin(event);
    },
    // 电话只能是数字
    verifyCptt_phone(event) {
      this.cpttForm.cptt_phone = this.helper.pureNumber(event);
    },
    // 公司地址英文
    verifyCptt_name_en(event) {
      this.cpttForm.cptt_name_en = keepEngAndSpace(event);
    }
  }
};
</script>

<style scoped lang="scss">
.vg_wrapper ::v-deep.el-form-item {
  margin: 0;
  padding: 0;
}
.vg_wrapper ::v-deep .el-table td,
::v-deep.el-table th {
  padding: 0;
}

.vg_wrapper ::v-deep .el-table .cell {
  padding: 0;
}
</style>
